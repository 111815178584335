import axios from 'axios';
import store from '@/store/index';

export default {
  install: app => {
    app.config.globalProperties.$http = axios;
    const $http = app.config.globalProperties.$http;

    $http.defaults.headers.common['Accept'] = 'application/json';
    $http.defaults.headers.common['Content-Type'] = 'application/json';
    $http.defaults.headers.common['X-CSRF-Token'] = document
      .querySelector('meta[name="csrf-token"]')
      ?.getAttribute('content');
    app.provide('http', app.config.globalProperties.$http);

    const handleResponse = response => {
      if ([200, 201, 202].includes(response.status) && response.data.message && !response.data.no_flash) {
        app.config.globalProperties.$notify(
          {
            group: 'app',
            type: 'flash',
            text: {
              type: 'notice',
              message: response.data.message
            }
          },
          store.getters.shortNotification
        );
      }
      return response;
    };

    const handleError = error => {
      if (error.response.data.message) {
        app.config.globalProperties.$notify(
          {
            group: 'app',
            type: 'flash',
            text: {
              type: 'error',
              message: error.response.data.message
            }
          },
          store.getters.shortNotification
        );
      } else if (error.response.data.errors) {
        error.response.data.errors.forEach(err => {
          app.config.globalProperties.$notify(
            {
              group: 'app',
              type: 'flash',
              text: {
                type: 'error',
                message: `${err.field} ${err.code}`
              }
            },
            store.getters.shortNotification
          );
        });
      }
      return Promise.reject(error);
    };
    $http.interceptors.response.use(handleResponse, handleError);
  }
};
